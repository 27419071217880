body {
  margin: 0;
  font-family: 'Luckiest Guy', cursive;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: hsl(141, 71%, 48%);
  --secondary-color: hsl(204, 86%, 53%);
  --danger-color: hsl(348, 100%, 61%);
  --white: white;
}
