.settings-container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.btn-start {
    background: var(--primary-color);
}

.btn-stop {
    background: var(--danger-color);
}

.range-container {
    width: 100%;
    border-radius: 10px;
    margin: 70px auto 15px auto;
    height: 18px;
    padding-top: 6px;
    border: 1px solid #CECECE;
}

.range-container .range {
    width: 96%;
    background-color: var(--secondary-color);
    margin: 0 auto;
    display: block;
    border-radius: 10px;
}

input[type="range"] {
    outline: 0;
    -webkit-appearance: none;
    background-color: black;
    height: 5px;
}

input[type="range"]::-webkit-slider-thumb{
    -webkit-appearance: none;
    position: relative;
    top: 0px;
    z-index: 1;
    width: 35px;
    height: 35px;
    border-radius: 40px;
    background-color: var(--primary-color);
    border: 1px solid #CECECE;
}

input[type=range]::-moz-range-thumb {
    background: var(--primary-color);
    width: 35px;
    height: 35px;
    border-radius: 40px;
    border: 1px solid #CECECE;
  }

input[type="text"] {
    outline: 0;
    font-size: 2.5rem;
    margin: 5px;
    padding: 10px;
    width: 95px;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
    font-family: 'Luckiest Guy', cursive;
};

.bpm-container {
    display: flex;
    flex-direction: column;

    & div:first-child{
        text-align: center;
    }
}

.bpm-container span{
    display: inline-flex;
    align-items: center;
}

.checkbox-beats{
    display: flex;
}

.checkbox-beats span{
    display: inline-flex;
    align-items: center;
}

.checkbox-beats input#check {
    position: absolute;
    opacity: 0;

    &:checked+label svg path {
        stroke-dashoffset: 101;
    }

    &:focus+label {
        transform: scale(1.03);
    }
}

.checkbox-beats #check+label {
    width: 90px; 
    height: 70px;  
    display: inline-block;
    border: 2px solid var(--primary-color);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
    margin: 5px;
    border-radius: 14px;
    cursor: pointer;
    transition: all .2s ease;

    &:active {
        transform: scale(1.05);
        border-radius: 30px;
    }

    svg {
        pointer-events: none;

        path {
            fill: none;
            stroke: var(--secondary-color);
            stroke-width: 8px;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 100;
            stroke-dashoffset: 0;
            transition: all 350ms cubic-bezier(1, 0, .37, .91);
        }
    }
}

.frequency-container{
    text-align: center;
    & input[type="text"] {
        width: 110px;
    };
    
}