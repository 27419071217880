#snackbar {
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    bottom: 0px;
    font-size: 1.5rem;
    visibility: hidden;
  }
  
  #snackbar button{
    display: inline-block;
    font-size: inherit;
    margin-left: 20px;
    background: none!important;
    border: none;
    font-family: inherit;
    padding: 0!important;
    cursor: pointer;
    color: var(--secondary-color);
  }
  

  #snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
  }
  
  @-webkit-keyframes fadein {
    from {bottom: -30px; opacity: 0;} 
    to {bottom: 0px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: -30px; opacity: 0;}
    to {bottom: 0px; opacity: 1;}
  }
