.App {
  text-align: center;
  background-color: white;
  height: 100vh;
}


.App-link {
  color: #61dafb;
}

.btn {
  transition: background 600ms;
  width: 100%;
  color: white;
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 15px 30px;
  cursor: pointer;
  border-width: 0;
  outline: none;
  border-radius: 35px;
  font-size: 1.5rem;
  font-weight: bold;
}

@media screen and (min-width: 991.98px) {
  .App {
    margin: auto;
    width: 40%;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 991.98px) {
  .App {
    width: initial;
    padding: 0 10px 0 10px;
    font-size: 1.5rem;

  }
}