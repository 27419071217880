*, *:before, *:after { box-sizing: border-box; }

.btn-modal{
    background: var(--primary-color);

}

.modal-arrow{
    font-size: 2.5rem;
    display: block;
    float: left;
    color: var(--danger-color);
}

.modal-container {
    .modal-backdrop {
      background-color: rgba(0, 0, 0, 0.6);
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9;
      visibility: visible;
      opacity: 1;      
      transition: opacity 0.2s ease-in; 
    }
    .modal-content {
      opacity: 1;
      background-color: #fff;
      padding: 10px 30px;
      position: fixed;
      top: 1%;
      width: 85%;
      border-radius: 4px;
      z-index: 999;
      visibility: visible;      
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6);  
      @media (max-width: 80%) { left: 0; } 
      
      & .btn{
          font-size: 1.5rem;
          margin-top: 25px;
      }
    }  
}

@media screen and (min-width: 991.98px) {
    .modal-container {
      display: none;
    }
  }
  
  @media screen and (max-width: 991.98px) {
    .modal-container {
        display: block;
      }
  }