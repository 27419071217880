.ios-prompt {
  background-color: var(--secondary-color);
  padding: 0.8rem 3rem 0.8rem 0.5rem;
  text-decoration: none;
  font-size: 16px;
  color: white;
  position: fixed;
  margin: 0 auto 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 10;
  bottom: 30px;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.ios-prompt:after {
  content:'';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  width: 0;
  height: 0;
  border-top: solid 40px var(--secondary-color);
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}

.ios-prompt img{
  width: 15px;
  margin-left: 5px;
  margin-right: 5px;
}

.ios-prompt p{
  margin: 0;
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 32px;
  height: 32px;
}

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
