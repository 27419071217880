body {
  margin: 0;
  font-family: 'Luckiest Guy', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

:root {
  --primary-color: hsl(141, 71%, 48%);
  --secondary-color: hsl(204, 86%, 53%);
  --danger-color: hsl(348, 100%, 61%);
  --white: white; }

.App {
  text-align: center;
  background-color: white;
  height: 100vh; }

.App-link {
  color: #61dafb; }

.btn {
  -webkit-transition: background 600ms;
  transition: background 600ms;
  width: 100%;
  color: white;
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 15px 30px;
  cursor: pointer;
  border-width: 0;
  outline: none;
  border-radius: 35px;
  font-size: 1.5rem;
  font-weight: bold; }

@media screen and (min-width: 991.98px) {
  .App {
    margin: auto;
    width: 40%;
    font-size: 2.5rem; } }

@media screen and (max-width: 991.98px) {
  .App {
    width: auto;
    width: initial;
    padding: 0 10px 0 10px;
    font-size: 1.5rem; } }

.settings-container {
  display: flex;
  flex-direction: column;
  text-align: left; }

.btn-start {
  background: var(--primary-color); }

.btn-stop {
  background: var(--danger-color); }

.range-container {
  width: 100%;
  border-radius: 10px;
  margin: 70px auto 15px auto;
  height: 18px;
  padding-top: 6px;
  border: 1px solid #CECECE; }

.range-container .range {
  width: 96%;
  background-color: var(--secondary-color);
  margin: 0 auto;
  display: block;
  border-radius: 10px; }

input[type="range"] {
  outline: 0;
  -webkit-appearance: none;
  background-color: black;
  height: 5px; }

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  top: 0px;
  z-index: 1;
  width: 35px;
  height: 35px;
  border-radius: 40px;
  background-color: var(--primary-color);
  border: 1px solid #CECECE; }

input[type=range]::-moz-range-thumb {
  background: var(--primary-color);
  width: 35px;
  height: 35px;
  border-radius: 40px;
  border: 1px solid #CECECE; }

input[type="text"] {
  outline: 0;
  font-size: 2.5rem;
  margin: 5px;
  padding: 10px;
  width: 95px;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
  font-family: 'Luckiest Guy', cursive; }

.bpm-container {
  display: flex;
  flex-direction: column; }
  .bpm-container div:first-child {
    text-align: center; }

.bpm-container span {
  display: inline-flex;
  align-items: center; }

.checkbox-beats {
  display: flex; }

.checkbox-beats span {
  display: inline-flex;
  align-items: center; }

.checkbox-beats input#check {
  position: absolute;
  opacity: 0; }
  .checkbox-beats input#check:checked + label svg path {
    stroke-dashoffset: 101; }
  .checkbox-beats input#check:focus + label {
    -webkit-transform: scale(1.03);
            transform: scale(1.03); }

.checkbox-beats #check + label {
  width: 90px;
  height: 70px;
  display: inline-block;
  border: 2px solid var(--primary-color);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
  margin: 5px;
  border-radius: 14px;
  cursor: pointer;
  -webkit-transition: all .2s ease;
  transition: all .2s ease; }
  .checkbox-beats #check + label:active {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    border-radius: 30px; }
  .checkbox-beats #check + label svg {
    pointer-events: none; }
    .checkbox-beats #check + label svg path {
      fill: none;
      stroke: var(--secondary-color);
      stroke-width: 8px;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 100;
      stroke-dashoffset: 0;
      -webkit-transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
      transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91); }

.frequency-container {
  text-align: center; }
  .frequency-container input[type="text"] {
    width: 110px; }

.full-circle {
  background-color: var(--primary-color); }

.empty-circle {
  background-color: white; }

.circle-visual-container {
  width: 100%; }

.circle-visual-container div {
  display: inline-block;
  margin: 5px;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  border: solid 1px var(--primary-color); }

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 30%;
  margin-bottom: 20px; }

.App-logo {
  height: 20vmin;
  pointer-events: none; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

*, *:before, *:after {
  box-sizing: border-box; }

.btn-modal {
  background: var(--primary-color); }

.modal-arrow {
  font-size: 2.5rem;
  display: block;
  float: left;
  color: var(--danger-color); }

.modal-container .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in; }

.modal-container .modal-content {
  opacity: 1;
  background-color: #fff;
  padding: 10px 30px;
  position: fixed;
  top: 1%;
  width: 85%;
  border-radius: 4px;
  z-index: 999;
  visibility: visible;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6); }
  @media (max-width: 80%) {
    .modal-container .modal-content {
      left: 0; } }
  .modal-container .modal-content .btn {
    font-size: 1.5rem;
    margin-top: 25px; }

@media screen and (min-width: 991.98px) {
  .modal-container {
    display: none; } }

@media screen and (max-width: 991.98px) {
  .modal-container {
    display: block; } }

#snackbar {
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  bottom: 0px;
  font-size: 1.5rem;
  visibility: hidden; }

#snackbar button {
  display: inline-block;
  font-size: inherit;
  margin-left: 20px;
  background: none !important;
  border: none;
  font-family: inherit;
  padding: 0 !important;
  cursor: pointer;
  color: var(--secondary-color); }

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s; }

@-webkit-keyframes fadein {
  from {
    bottom: -30px;
    opacity: 0; }
  to {
    bottom: 0px;
    opacity: 1; } }

@keyframes fadein {
  from {
    bottom: -30px;
    opacity: 0; }
  to {
    bottom: 0px;
    opacity: 1; } }

.ios-prompt {
  background-color: var(--secondary-color);
  padding: 0.8rem 3rem 0.8rem 0.5rem;
  text-decoration: none;
  font-size: 16px;
  color: white;
  position: fixed;
  margin: 0 auto 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 10;
  bottom: 30px;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif; }

.ios-prompt:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  width: 0;
  height: 0;
  border-top: solid 40px var(--secondary-color);
  border-left: solid 15px transparent;
  border-right: solid 15px transparent; }

.ios-prompt img {
  width: 15px;
  margin-left: 5px;
  margin-right: 5px; }

.ios-prompt p {
  margin: 0; }

.close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 32px;
  height: 32px; }

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: white; }

.close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

